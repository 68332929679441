import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ActivityService} from '../services/activity.service';
import {H5PActivity} from '@shared/models/lessons.model';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class HvpResolverService implements Resolve<H5PActivity> {

  constructor(private activityService: ActivityService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<H5PActivity> | Promise<H5PActivity> | H5PActivity {
    const hvpId = route.params.id;
    const courseId = route.params.courseId;
    return this.activityService.getHVPContent(courseId, hvpId);
  }
}
