import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ActivityService} from '../services/activity.service';
import {Assignment} from '@shared/models/assignment.model';
import {AssignmentService} from '@shared/services/assignment.service';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class AssignmentResolverService implements Resolve<Assignment> {

  constructor(private assignmentService: AssignmentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<Assignment> | Promise<Assignment> | Assignment {
    const assignId = route.params.id;
    return this.assignmentService.getAssignmentStatus(assignId);
  }
}
