import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Course, Lesson} from '../models/lessons.model';
import {map} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class StationsService {

  constructor(private http: HttpClient) {
  }

  getCourseByName(courseName: string): Observable<Course> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('wsfunction', 'core_enrol_get_users_courses');
    httpParams = httpParams.set('coursename', courseName);
    const opts = {params: httpParams};
    return this.http.get<Course>('', opts).pipe(map(response => {
      return response[0];
    }));
  }

  getLesson(courseId: string, lessonId: string): Observable<Lesson> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('wsfunction', 'core_course_get_contents');
    httpParams = httpParams.set('courseid', courseId);
    httpParams = httpParams.set('options[0][name]', 'sectionid');
    httpParams = httpParams.set('options[0][value]', lessonId);
    const opts = {params: httpParams};
    return this.http.get<Lesson>('', opts);
  }
}
