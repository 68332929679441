import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {StationsService} from '../services/stations.service';
import {Course} from '../models/lessons.model';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class StationsResolverService implements Resolve<Course> {

  constructor(private stationsService: StationsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<Course> | Promise<Course> | Course {
    const courseName = this.getCourseName(state.url.split('/')[2]);
    return this.stationsService.getCourseByName(courseName);
  }

  private getCourseName(path: string): string {
    let categoryName: string;
    switch (path) {
      case  'deserts': {
        categoryName = 'الصحراء';
        break;
      }
      case 'seas': {
        categoryName = 'البحار والمحيطات';
        break;
      }
      case 'spaces': {
        categoryName = 'السماء والنجوم';
        break;
      }
      case 'plains': {
        categoryName = 'السهول والوديان';

        break;
      }
      case 'rivers': {
        categoryName = 'الأنهار والينابيع';

        break;
      }
      case 'mountains': {
        categoryName = 'الجبال';
        break;
      }
    }
    return categoryName;
  }
}
