import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Lesson} from '../models/lessons.model';
import {StationsService} from '../services/stations.service';

@Injectable({
  providedIn: 'root'
})
@Injectable()
export class ActivitiesResolverService implements Resolve<Lesson> {

  constructor(private stationsService: StationsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<Lesson> | Promise<Lesson> | Lesson {
    const courseId = route.params.courseId;
    const lessonId = route.params.lessonId;
    return this.stationsService.getLesson(courseId, lessonId);
  }
}
