import {ActivityIconEnum} from '@shared/shared';

class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}

export class Points {
  static allPoints = [
    [new Point(290.7, 191.1), new Point(450, 191.1)],
    [new Point(328.9, 65.15), new Point(488, 65.15)],
    [new Point(347.3, -75.9), new Point(507.3, -75.9)],
    [new Point(279.2, -203.25), new Point(439.2, -203.25)],
    [new Point(191.1, -301.85), new Point(351.1, -301.85)],
    [new Point(53.55, -355.85), new Point(53.55, -240.85)],
    [new Point(-96.25, -347.2), new Point(-96.25, -220.85)],
    [new Point(-225.2, -292.25), new Point(-375.2, -292.25)],
    [new Point(-321.35, -182.55), new Point(-450.35, -182.55)],
    [new Point(-377.6, -65.05), new Point(-500.6, -65.05)],
    [new Point(-359.85, 80.55), new Point(-500.6, 80.55)],
    [new Point(-276.1, 210.05), new Point(-400.6, 210.05)],
  ];
}

